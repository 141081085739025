.text-overlay {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  text-shadow: 2px 2px 15px rgba(0, 0, 0, 1);
  text-align: center;
  background-color: gold !important;
}
.container {
  position: relative;
}

.blurred {
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
  width: 100px;
}

div[hidden] {
  display: none;
}

.fc.fc-media-screen.fc-direction-ltr.fc-theme-standard {
  height: 100%;
}

.clicked-row {
  background-color: #9fa8da;
}

.clicked-row:hover {
  background-color: #7b88d6 !important;
}

img.top-bar-logo {
  max-height: 50px;
}

.fa-spin {
  -webkit-animation: fa-spin 1s ease-in-out infinite;
  animation: fa-spin 1s ease-in-out infinite;
}

@keyframes fa-spin {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

.hidden {
  display: none;
}

.center-loader {
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}

.fade-out {
  animation: fadeOut ease 1s;
  -webkit-animation: fadeOut ease 1s;
  -moz-animation: fadeOut ease 1s;
  -o-animation: fadeOut ease 1s;
  -ms-animation: fadeOut ease 1s;
}
@keyframes fadeOut {
  0% {opacity:1; visibility: visible;}
  100% {opacity:0; visibility: hidden;}
}

@-moz-keyframes fadeOut {
  0% {opacity:1; visibility: visible;}
  100% {opacity:0;visibility: hidden;}
}

@-webkit-keyframes fadeOut {
  0% {opacity:1; visibility: visible;}
  100% {opacity:0;visibility: hidden;}
}

@-o-keyframes fadeOut {
  0% {opacity:1; visibility: visible;}
  100% {opacity:0;visibility: hidden;}
}

@-ms-keyframes fadeOut {
  0% {opacity:1; visibility: visible;}
  100% {opacity:0; visibility: hidden;}
}
.fade-in {
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;
}

@keyframes fadeIn {
  0% { opacity:0; visibility: visible;}
  100% { opacity:1; visibility: visible;}
}
@-moz-keyframes fadeIn {
  0% {opacity:0; visibility: visible;}
  100% {opacity:1; visibility: visible;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:0; visibility: visible;}
  100% {opacity:1; visibility: visible;}
}

@-o-keyframes fadeIn {
  0% {opacity:0;  visibility: visible;}
  100% {opacity:1; visibility: visible;}
}

@-ms-keyframes fadeIn {
  0% {opacity:0;  visibility: visible;}
  100% {opacity:1; visibility: visible;}
}